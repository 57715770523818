<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->

      <UserMenu></UserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script>
import { defineComponent } from "vue";
import UserMenu from "./partials/UserMenu.vue";
export default defineComponent({
  name: "topbar",
  components: {
    UserMenu,
  },
});
</script>
