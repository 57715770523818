<template>
  <!--begin::Aside-->
  <div
    id="kt_aside"
    class="aside aside-hoverable aside-light"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <!--begin::Logo-->

      <a href="#">
        <span class="logo logo-text">เมืองแม่ฮ่องสอน</span
        ><img alt="Logo" :src="myLogoSrc" class="logo dis" />
      </a>
      <!--end::Logo-->
      <!--begin::Aside toggler-->
      <div
        id="kt_aside_toggle"
        class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <span class="svg-icon svg-icon-1 rotate-180">
          <inline-svg :src="myMenuSrc" />
        </span>
      </div>
      <!--end::Aside toggler-->
    </div>
    <!--end::Brand-->

    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <Menu></Menu>
    </div>
    <!--end::Aside menu-->
  </div>
  <!--end::Aside-->
</template>

<script>
import myMenuSrc from "@/assets/media/icons/duotone/Navigation/Angle-double-left.svg";
import myLogoSrc from "@/assets/media/logos/logo.png";
import { defineComponent, onMounted, onUpdated } from "vue";
import { DrawerComponent } from "@/assets/js/components/_DrawerOptions";
import { ToggleComponent } from "@/assets/js/components/_ToggleComponent";
import Menu from "@/views/layout/aside/Menu.vue";

export default defineComponent({
  name: "Aside",
  components: {
    Menu,
  },
  setup() {
    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      myMenuSrc,
      myLogoSrc,
    };
  },
});
</script>
<style scoped>
.logo-text {
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.dis {
  position: absolute;
  height: 50px;
  top: 10px;
}
</style>
