const DocMenuConfig = [
  {
    pages: [
      {
        heading: "หน้าหลัก",
        route: "/dashboard",
        permission: "dashboard",
        fontIcon: "bi bi-house",
      },
    ],
  },
  {
    heading: "ระบบ",
    route: "/apps",
    pages: [
      {
        sectionTitle: "ร้องเรียน",
        route: "/appeals",
        permission: "appeal-list",
        fontIcon: "bi bi-megaphone",
        sub: [
          {
            sectionTitle: "ใบงานร้องเรียน",
            route: "/appeal",
            sub: [
              {
                heading: "รอตรวจสอบ",
                permission: "appeal-list",
                route: "/apps/appeals/appeal/wait",
              },
              {
                heading: "รับคำร้อง",
                permission: "appeal-list",
                route: "/apps/appeals/appeal/receive",
              },
              {
                heading: "ดำเนินการ",
                permission: "appeal-list",
                route: "/apps/appeals/appeal/processing",
              },
              {
                heading: "เสร็จสิ้น",
                permission: "appeal-list",
                route: "/apps/appeals/appeal/finish",
              },
            ],
          },
          {
            heading: "จัดการหัวข้อเรื่อง",
            permission: "title-list",
            route: "/apps/appeals/title",
          },
          {
            heading: "จัดการหัวข้อเรื่องย่อย",
            permission: "sub-title-list",
            route: "/apps/appeals/sub-title",
          },
        ],
      },
      {
        sectionTitle: "ส่งเสริมการท่องเที่ยว",
        route: "/travels",
        permission: "travel-list",
        fontIcon: "bi bi-pin-map-fill",
        sub: [
          {
            heading: "แนะนำที่ท่องเที่ยว",
            permission: "tourist-attraction-list",
            route: "/apps/travels/tourist-attraction",
          },
          {
            heading: "ปฏิทินท่องเที่ยว",
            permission: "calendar-travel-list",
            route: "/apps/travels/calendar-travel",
          },
          {
            sectionTitle: "สินค้าชุมชน",
            route: "/otop",
            permission: "otop-list",
            sub: [
              {
                heading: "ร้าน",
                permission: "otop-store-list",
                route: "/apps/travels/otop/store",
              },
              {
                heading: "ประเภทสินค้า",
                permission: "otop-product-type-list",
                route: "/apps/travels/otop/product-type",
              },
              {
                heading: "สินค้า",
                permission: "otop-product-list",
                route: "/apps/travels/otop/product",
              },
            ]
          },
          {
            sectionTitle: "ผู้ประกอบการ",
            route: "/entrepreneur",
            permission: "entrepreneur-list",
            sub: [
              {
                heading: "ร้าน",
                permission: "entrepreneur-store-list",
                route: "/apps/travels/entrepreneur/store",
              },
              {
                heading: "ประเภทสินค้า",
                permission: "entrepreneur-product-type-list",
                route: "/apps/travels/entrepreneur/product-type",
              },
              {
                heading: "สินค้า",
                permission: "entrepreneur-product-list",
                route: "/apps/travels/entrepreneur/product",
              },
            ]
          },
          {
            sectionTitle: "ตลาดนัดวันอาทิตย์",
            route: "/market_sun",
            permission: "market_sun-list",
            sub: [
              {
                heading: "ร้าน",
                permission: "market_sun-store-list",
                route: "/apps/travels/market_sun/store",
              },
              {
                heading: "ประเภทสินค้า",
                permission: "market_sun-product-type-list",
                route: "/apps/travels/market_sun/product-type",
              },
              {
                heading: "สินค้า",
                permission: "market_sun-product-list",
                route: "/apps/travels/market_sun/product",
              },
            ]
          },
          {
            sectionTitle: "ตลาดสายหยุด",
            route: "/market_saiyut",
            permission: "market_saiyut-list",
            sub: [
              {
                heading: "ร้าน",
                permission: "market_saiyut-store-list",
                route: "/apps/travels/market_saiyut/store",
              },
              {
                heading: "ประเภทสินค้า",
                permission: "market_saiyut-product-type-list",
                route: "/apps/travels/market_saiyut/product-type",
              },
              {
                heading: "สินค้า",
                permission: "market_saiyut-product-list",
                route: "/apps/travels/market_saiyut/product",
              },
            ]
          }
        ],
      },

      {
        sectionTitle: "ดูแลสุขภาพ",
        route: "/smarthealths",
        permission: "smarthealth-list",
        fontIcon: "bi bi-emoji-smile",
        sub: [
          {
            sectionTitle: "เจ้าหน้าที่ อ.ส.ม",
            route: "/smarthealths_admin",
            permission: "smarthealth-admin-list",
            sub: [
              {
                heading: "รายชื่อ",
                permission: "smarthealth-admin-list",
                route: "/apps/smarthealths/smarthealths_admin",
              },
              
            ]
          }
        ]
      },
      {
        sectionTitle: "สอบถามภาษี",
        route: "/taxs",
        permission: "tax-list",
        fontIcon: "bi bi-currency-exchange",
        sub: [
          {
            heading: "รอตรวจสอบ",
            permission: "tax-list",
            route: "/apps/taxs/tax/wait",
          },
         
          {
            heading: "ดำเนินการ",
            permission: "tax-list",
            route: "/apps/taxs/tax/processing",
          },
          {
            heading: "เสร็จสิ้น",
            permission: "tax-list",
            route: "/apps/taxs/tax/finish",
          },
          {
            heading: "รายงาน",
            permission: "tax-list",
            route: "/apps/taxs/tax/report",
          },
          {
            heading: "จัดการ Notify",
            permission: "tax-notify-list",
            route: "/apps/taxs/notify",
          },
        ]
      },
      {
        sectionTitle: "ขออนุญาตก่อสร้าง",
        route: "/building_permit",
        permission: "building_permit-list",
        fontIcon: "bi bi-cone-striped",
        sub: [
          {
            heading: "รอตรวจสอบ",
            permission: "building_permit-list",
            route: "/apps/building_permit/wait",
          }
        ]
      },
      {
        sectionTitle: "ขออนุญาต",
        route: "/building_permits",
        permission: "building_permit-list",
        fontIcon: "bi bi-cone-striped",
        sub: [
          {
            heading: "รอตรวจสอบ",
            permission: "building_permit-list",
            route: "/apps/building_permits/wait",
          }
        ]
      }
    ],
  },
  {
    heading: "รายงาน",
    route: "/reports",
    pages: [
      {
        heading: "รายงานสรุป",
        route: "/reports/report_all",
        permission: "report_all-list",
        fontIcon: "bi bi-clipboard-data",
      },    
    ],
  },
  {
    heading: "จักการ",
    route: "/settings",
    pages: [
      {
        heading: "สิทธิ์การเข้าถึง",
        route: "/settings/permissions",
        permission: "permission-list",
        fontIcon: "bi bi-file-lock",
      },
      {
        heading: "ระดับการเข้าถึง",
        route: "/settings/roles",
        permission: "role-list",
        fontIcon: "bi bi-file-lock2",
      },
      {
        heading: "ผู้ใช้งาน",
        route: "/settings/users",
        permission: "user-list",
        fontIcon: "bi bi-people",
      },
    ],
  },
];
export default DocMenuConfig;
