<template>
  <!--begin::Page-->
  <div class="page d-flex flex-row flex-column-fluid">
    <Aside />

    <!--begin::Wrapper-->
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <Header />
      <!-- begin:: Content -->
      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <Toolbar :breadcrumbs="breadcrumbs" :title="pageTitle" />

        <!-- begin:: Content Body -->
        <div class="post d-flex flex-column-fluid">
          <div class="container">
            <router-view />
          </div>
        </div>
        <!-- end:: Content Body -->
      </div>
      <!--end::Content-->

      <!--begin::Footer-->
      <Footer />
      <!--end::Footer-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Page-->

  <!--begin::Scrolltop-->
  <Scrolltop />
  <!--end::Scrolltop-->
  <!--end::Main-->
</template>

<script>
document.body.classList.add(
  "header-fixed",
  "header-tablet-and-mobile-fixed",
  "toolbar-enabled",
  "toolbar-fixed",
  "toolbar-tablet-and-mobile-fixed",
  "aside-enabled",
  "aside-fixed"
);
import { defineComponent, onMounted, onUpdated, computed } from "vue";
import Aside from "@/views/layout/aside/Aside.vue";
import Header from "@/views/layout/header/Header.vue";
import Footer from "@/views/layout/footer/Footer.vue";
import Scrolltop from "@/views/layout/extras/Scrolltop.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Toolbar from "@/views/layout/toolbar/Toolbar.vue";
import {
  MenuComponent,
  DrawerComponent,
  ToggleComponent,
  ScrollComponent,
} from "@/assets/js/components";

export default defineComponent({
  name: "Layout",
  components: {
    Aside,
    Header,
    Toolbar,
    Footer,
    Scrolltop,
  },

  setup() {
    const loaderEnabled = true;
    const store = useStore();
    const router = useRouter();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      if (!store.getters.isAuthenticated) {
        router.push({ name: "login" });
      }
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });
    return {
      loaderEnabled,
      breadcrumbs,
      pageTitle,
    };
  },
});
</script>

<style></style>
