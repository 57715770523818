<template>
  <!--begin::User-->
  <div
    v-if="currentUser"
    class="d-flex align-items-center ms-1 ms-lg-3"
    id="kt_header_user_menu_toggle"
  >
    <!--begin::Menu wrapper-->
    <div
      class="cursor-pointer symbol symbol-30px symbol-md-40px"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="bottom"
    >
      <img
        v-if="currentUser.avatars === null || !currentUser.avatars"
        alt="Logo"
        :src="myBlankSrc"
      />
    </div>
    <!--begin::Menu-->
    <div
      class="
        menu
        menu-sub
        menu-sub-dropdown
        menu-column
        menu-rounded
        menu-gray-600
        menu-state-bg
        menu-state-primary
        fw-bold
        py-4
        fs-6
        w-275px
      "
      data-kt-menu="true"
    >
      <!--begin::Menu item-->
      <div class="menu-item px-3">
        <div class="menu-content d-flex align-items-center px-3">
          <!--begin::Avatar-->
          <div class="symbol symbol-50px me-5">
            <img
              alt="Logo"
              v-if="currentUser.avatars === null || !currentUser.avatars"
              :src="myBlankSrc"
            />
          </div>
          <!--end::Avatar-->
          <!--begin::Username-->
          <div class="d-flex flex-column">
            <div class="fw-bolder d-flex align-items-center fs-5">
              {{ currentUser.name }}

              <span
                class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
                >Pro</span
              >
            </div>
            <a class="fw-bold text-muted text-hover-primary fs-7">{{
              currentUser.email
            }}</a>
          </div>
          <!--end::Username-->
        </div>
      </div>
      <!--end::Menu item-->
      <!--begin::Menu separator-->
      <div class="separator my-2"></div>
      <!--end::Menu separator-->
      <!--begin::Menu item-->
      <div class="menu-item px-5">
        <a class="menu-link px-5">My Profile</a>
      </div>
      <!--end::Menu item-->

      <!--begin::Menu item-->
      <div class="menu-item px-5 my-1">
        <a class="menu-link px-5">Account Settings</a>
      </div>
      <!--end::Menu item-->
      <!--begin::Menu item-->
      <div class="menu-item px-5">
        <a @click="onLogout" class="menu-link px-5">ออกจากระบบ</a>
      </div>
      <!--end::Menu item-->
    </div>
    <!--end::Menu-->
    <!--end::Menu wrapper-->
  </div>
  <!--end::User -->
</template>
<script>
import myBlankSrc from "@/assets/media/avatars/blank.png";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { LOGOUT } from "@/store/modules/AuthModule";
import { useRouter } from "vue-router";
import useSweetalert from "@/core/helpers/sweetalert2";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const { Sconfirm } = useSweetalert();
    let currentUser;
    if (JwtService.getUsers()) {
      currentUser = JSON.parse(JwtService.getUsers());
    }

    const onLogout = () => {
      Sconfirm("ยืนยัน ออกจากระบบ", "question").then(function () {
        store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
      });
    };

    return { currentUser, onLogout, myBlankSrc };
  },
});
</script>

<style></style>
