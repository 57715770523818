<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      class="container-xxl d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg :src="myMenuSrc" />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="#" class="d-lg-none">
          <img alt="Logo" :src="myLogoSrc" class="h-30px" />
        </a>
      </div>
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <!--end::Navbar-->
        <Menu />
        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <Topbar></Topbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
import myMenuSrc from "@/assets/media/icons/duotone/Text/Menu.svg";
import myLogoSrc from "@/assets/media/logos/logo.png";
import { defineComponent } from "vue";
import Topbar from "./Topbar.vue";
import Menu from "./Menu.vue";
export default defineComponent({
  name: "KTHeader",

  components: {
    Topbar,
    Menu,
  },
  setup() {
    return {
      myMenuSrc,
      myLogoSrc,
    };
  },
});
</script>
