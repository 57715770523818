<template>
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="
        container-fluid
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-between
      "
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2021©</span>
        <a class="text-gray-800 text-hover-primary">bacos25</a>
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a class="menu-link px-2">About</a>
        </li>
        <li class="menu-item">
          <a class="menu-link px-2">Support</a>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
